import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/soubhagya.jpg';

const AdvocateShoubhagyaDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mrs. Soubhagya Valli has outstanding academic record. She
                obtained Gold Medal in Law from Padala Rami Reddy Law College
                affiliated to Osmania University, Hyderabad. Had her
                post-graduation L.L.M from Wolverhampton University, West
                Midlands (UK). She enrolled as an Advocate in the year 2007. She
                is specialised in the areas of International Corporate and
                Financial Laws, Corporate Mergers & Acquisition, Financial
                Crimes and Company Law. She is well versed in US FRCP laws. She
                worked as Legal Associate in a well known Solicitors firm, UK as
                an independent in charge of UK Immigration and Commercial law
                cases. She has also worked as a Legal Consultant and Advocate
                with UK Barristers for their Indian Office at New Delhi on
                Arbitrations and Environmental litigations. She worked as a
                Senior Legal Analyst for a leading MNC dealing with overseas
                legal issues of the Company. She is practicing presently at
                Hon’ble High Court of Telangana and Andhra Pradesh, National
                Company law Tribunal, City Civil Courts, Criminal Courts,
                Arbitrations at Hyderabad. She has co-authored several
                Commentaries on Law with her Senior Mr. V. S. R. Avadhani.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  Mrs. V Soubhagya Valli. LLM (UK)
                </h2>
                <p className='team-one__designation'>
                  Partner, Consultant & Advocate
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateShoubhagyaDetails;
