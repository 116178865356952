import AdvocateShoubhagyaDetails from '../../components/about/advocates-details/shoubhagya';
import Footer from '../../components/common/footer';
import Layout from '../../components/common/layout';
import NavigationMenu from '../../components/common/navigationMenu';
import PageHeader from '../../components/common/pageHeader';
import React from 'react';

const AdvocateShoubhagyaDetailsPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | V. Soubhagya Valli'>
      <NavigationMenu active='about' />
      <PageHeader
        title='V. Soubhagya Valli'
        subMenu='Advocates'
        backLink='/about#team-section'
      />
      <AdvocateShoubhagyaDetails />
      <Footer />
    </Layout>
  );
};

export default AdvocateShoubhagyaDetailsPage;
